.App {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw; // Ensure the container takes the full viewport width
  height: 100vh; // Ensure the container takes the full viewport height
  position: relative; // Ensure the container is positioned relative to its parent
  background-color: black; // Set background color to black

  video {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 1; // Set the z-index of the video
  }

  img {
    width: 400px; // Set the width of the image to 20% of the container
    position: absolute;
    z-index: 2; // Set the z-index of the image higher than the video
  }

  .top-right {
    top: 10px; // Margin from the top edge
    right: 10px; // Margin from the right edge
  }

  .top-left {
    top: 60px; // Margin from the top edge
    left: 100px; // Margin from the left edge
  }

  @media (max-width: 1200px) {
    .top-right {
      top: 10px;
      right: 50%;
      transform: translateX(50%);
    }

    .top-left {
      top: 80%;
      width: 300px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}